@import "fonts";
@import "mixins";
@import "reset";
@import "flexbox";
@import "flags/less/flag-icon";

//red color scheme
@primaryColor: #D61548;
@primaryColorLight: #E86565;
@greenColor: #1ab394;
@greenColorLight: #1ab394;

body{
    width: 100%;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    color: #333;
}

header{
        .top-logo{
        @media (max-width: 360px){margin-top: 10px;}
        .logo{
            background: url(../img/new-logo-whitebg-245.jpg) no-repeat;
            height: 44px;
            width: 110px;
            background-size: contain;
            display: inline-block;
            vertical-align: top;
        }
        .b2b{
            font-family: 'Montserrat', sans-serif;
            color: #B5B6B7;
            font-size: 45px;
            font-weight: 300;
            line-height: 40px;
            display: inline-block;
            vertical-align: top;
        }
    }
    .menu-button{
        display: none;
        float: right;
        svg{
            width: 40px;
            height: 40px;
            color: #444;
        }
    }
}



//делает пачку классов .padding-10{padding:10px;}.padding-20{padding:20px;}....padding-100{...}
.loop(@counter) when (@counter > 0) {
    .loop((@counter - 1));
    @px: @counter * 10;
    .padding-@{px}{ padding: @counter * 10px; @media (max-width: 360px){padding: @counter * 3px @counter * 5px;}}

}
.loop(10);

.top{
    min-height: 377px;
    background: url(../img/mobile/promo-top-logo.jpg) transparent center center no-repeat;
    background-size: cover;
}

.title{
    font-size: 1.5em;
    margin: 30px 0 23px 0;
    font-weight: 600;
    line-height: 34px;
}

.features{
    margin-bottom: 20px;
    &__item{
        height: 30px;
    }
    &__check{
        float: left;
        margin-right: 7px;
        svg{
            color: @primaryColor;
        }
    }
}

.styled-button{
    background-color: #D61548;
    color: white;
    text-decoration: none;
    padding: 0 20px;
    font-size: 13px;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: bold;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    font-family: 'Noto Sans', sans-serif;
    &:hover{
        background-color: fade(@primaryColor, 80%);
    }
    &:hover, &:focus, &:active{
        text-decoration: none;
        color: white;
    }
    .icon{
        width: 16px;
        vertical-align: middle;
        margin-top: -3px;
    }
}

.video-box{
    filter: drop-shadow(0 1px 9px rgba(0,0,0,0.06));
    &.is-playing{
        .thumbnail{
            background: none;
        }
        .player{
            display: block;
        }
    }
    .thumbnail{
        width: 100%;
        height: 100%;
        min-height: 236px;
        display: block;
        background: url(../img/mobile/video-thumb.png) no-repeat center center;
        background-size: contain;
    }
    .player{
        display: none;
    }
}

.limited-size{
    max-width: 500px;
    width: 100%;
}

.form{
    margin-bottom: 30px;
    &__input-box{
        margin-bottom: 16px;
    }
    &__input{
        color: black;
        font-size: 15px;
        background-color: #EFF2F3;
        padding: 0 20px;
        line-height: 40px;
        border: 1px solid #E3E3E3;
        width: 100%;
        border-radius: 2px;
        outline: none!important;
        &:focus{
            box-shadow: 0 0 2px 2px fade(@primaryColor, 40%);
        }
    }
    &__button{
        margin-right: 10px;
    }
}

.small-text{
    color: darkgrey;
    font-size: 12px;
    margin-bottom: 15px;
    line-height: 17px;
    &_light{
        color: #c2c2c2;
    }
}

.links{
    margin-bottom: 5px;
    text-transform: uppercase;
    display: none;
    &__link{
        color: #333;
        text-decoration: none;
        margin-right: 20px;
    }
}

.wrap-videoBox {
    margin-top: 30px;
}
.header-button {
    margin-bottom: 34px;
}

#videoBox {
    width: 100%;
    max-width: 740px;
    height: 416px;
}

@media(max-width: 600px) {
    #videoBox {
        height: 300px;
    }
}

@media(max-width: 430px) {
    #videoBox {
        height: 236px;
    }
}
